import dayjs from "dayjs"

const states = {
  history_details: {
    filters_data: [], //TODO: remove this state and use page_details
    chat_list: [],
    total_count: 0,
    load_more: false,
    selected_chat: {
      chat_item: null,
      messages: [],
      profile: {},
    },
    selected_date_range: [
      dayjs(),
      dayjs(),
    ],
    selected_filters: {}, //do not define any initial state inside selected_filters**
  },
}

export default states
