import dayjs from "dayjs"

const states = {
  tickets_details: {
    is_socket_conneted: false,
    //============= Tickets Home page ================
    tickets: [],
    total_tickets: 0,
    status: "",
    selected_date: [
      dayjs().subtract(15, "day"),
      dayjs(),
    ],
    sort_order: null,
    sort_field: null,
    current_page: 1,
    page_size: 10,
    loading: false,
    //============= Ticket View Page ================
    selected_ticket: null,
    messages: [],
    profile: {},
    profile_loading: false,
    page_joining: false,
    //============= Ticket Analytics Page ================
    selected_date_range: [
      dayjs().subtract(10, "day"),
      dayjs(),
    ],
    selected_platform: "all",
    stats: [],
    resolved_tickets_data: [],
    rt_granularity: "day",
    rt_loading: false,
    rt_load_error: false,
  },
}

export default states
