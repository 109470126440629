import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/browser"
import Push from "push.js"
import { message, notification } from "antd"
import { registerTheme } from "bizcharts"
import { DndProvider } from "react-dnd"
import { HTML5Backend as Backend } from "react-dnd-html5-backend"

import * as serviceWorker from "./serviceWorker"
import configureStore from "./data/store/configureStore"

import "./data/styles/index.scss"
import "react-quill/dist/quill.snow.css"
import "codemirror/theme/3024-night.css"
// import "antd/lib/carousel/style/index.css"

import { SENTRY_DSN } from "./data/config/urls"
import { COLORS } from "./data/constants/layout"
import { dynamicallyLoadScript } from "./data/utils/dataUtility"

import App from "./App"

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    "ResizeObserver loop completed with undelivered notifications.",
    "ResizeObserver loop limit exceeded",
  ],
})

registerTheme("light", {
  defaultColor: COLORS.primary,
})

message.config({
  duration: 2,
  maxCount: 3,
})

notification.config({
  placement: "bottomRight",
  bottom: 50,
  duration: 3,
})

Push.config({
  fallback: function () {
    message.warning("This browser does not support push notification.", 5)
  },
})

if (!Push.Permission.has())
  Push.Permission.request(
    () => {
      message.success("Notification granted")
    },
    () => {
      notification.warning({
        message: "Notification denied",
        description:
          "You will not able to received any notification from dashboard. To grant permission, go to browser settings and allow permission for notifications",
        duration: 0,
      })
    }
  )

export const store = configureStore({})

render(
  <Provider store={store}>
    <DndProvider backend={Backend}>
        <App />
    </DndProvider>
  </Provider>,
  document.getElementById("ori-chatbot-dashboard-2.0")
)

if (
  process.env.REACT_APP_CHATBOT_URL &&
  process.env.REACT_APP_ADD_CHATBOT_ON_DASHBOARD === "true"
)
  dynamicallyLoadScript(process.env.REACT_APP_CHATBOT_URL)

serviceWorker.unregister()
