import dayjs from "dayjs"
const states = {
  ticket_history_details: {
    ticketlogs_filters: [],
    ticket_list: [],
    total_count: 0,
    load_more: false,
    selected_ticket: {
      ticket_item: null,
      messages: [],
      profile: {},
      liveChats: []
    },
    ticket_list_count: 0,
    selected_ticket_id: "",
    selected_date_range: [
      dayjs(),
      dayjs(),
    ],
    selected_filters: {},
    redirect_ticket_id: "",
  },
}

export default states
