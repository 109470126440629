import dayjs from "dayjs"

const states = {
  session_flow_details: {
    session_flow: {},
    toggle_startover: false,
    isGroupLoading: false,
    session_flow_loading: false,
    session_flow_load_error: false,
    session_flow_direction: true,
    session_flow_levels: 1,
    selected_node: { id: 0, name: "Session Start" },
    session_flow_default_filters: {},
    selectedGroup: {},
    viewList: [],
    selected_view: {},
    filter: [],
    groupList: [],
    eventList: [],
    viewAction: {},
    session_flow_filters: {
      startDate: dayjs().subtract(1, "day"),
      endDate: dayjs(),
    },
  },
}

export default states
