import Chatting from "./img/chatting"
import Error from "./img/error"
import Empty from "./img/empty"
import Graph from "./img/graph"
import Person from "./img/person"

//-------------------------- IMAGE ---------------------------
export const IMG_LOGO = require("./img/logo.png")
export const IMG_LOGO_DARK = require("./img/logoDark.png")
export const IMG_LOGO_AVATAR = require("./img/logoAvatar.png")
export const IMG_LOGO_AVATAR_DARK = require("./img/logoAvatarDark.png")
export const IMG_LOGIN_BG = require("./img/loginBg.png")
export const IMG_BOT_AVATAR = require("./img/botAvatar.png")
export const IMG_USER = require("./img/user.png")
export const IMG_DODDLE = require("./img/doddle.png")
export const IMG_AUTH_BG = require("./img/authBg.png")
export const IMG_MICROSOFT_LOGO = require("./img/microsoft.png")
export const IMG_NEW_MESSAGE = require("./img/newMessage.png")
export const IMG_NEW_USER = require("./img/newUser.png")

//-----------IMG_COMPONENT-----------------
export const IMG_ERROR = Error
export const IMG_GRAPH = Graph
export const IMG_EMPTY = Empty
export const IMG_PERSON = Person
export const IMG_CHATTING = Chatting

//-------------------------- AUDIO ---------------------------

export const AUDIO_NOTIFICATION = require("./audio/notification.mp3")
