import dayjs from "dayjs"

const states = {
  customers_details: {
    //------------------- USERS HOME PAGE --------------------
    customers: [],
    columns: [],
    customers_search_key: "",
    has_more_customers: false,
    current_customers_page: 1,
    customers_loading: false,
    customers_load_error: false,
    //------------------- SELECTED USER PROFILE PAGE --------------------

    customer_profile: {
      filters: {
        startDate: dayjs().subtract(14, "day"),
        endDate: dayjs(),
        platform: "all",
      },
      basic_info: {},
      basic_info_loading: false,
      basic_info_load_error: false,

      stats: [],
      stats_loading: false,

      event_histogram: [],
      event_histogram_loading: false,
      event_histogram_load_error: false,

      chatlogs_selected_filters: {},
      chatlogs: [],
      chatlogs_loading: false,
      total_chatlogs_count: 0,
      load_more_chatlogs: false,
      selected_chat: {
        chat_item: null,
        messages: [],
        profile: {},
      },
    },
  },
}

export default states
