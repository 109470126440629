import { getDataFromLocalStorage } from "../../utils/dataUtility"
import { LOCAL_STORAGE } from "../../constants/common"

/*
//----------- live_chats -----------
  live_chats: {
    [psid]: {...}
  }

//----------- active_chats ------------
  active_chats: {
    [psid]: {
      messages: [...],
      profile: {...},
      tags:[...],
      selected_tags: [...],
      typing_info: {...},
      selected_language: "en"
    }
  }

*/

const states = {
  chats_details: {
    is_socket_connected: false,
    agent_states: [],
    agent_current_state: {},
    agent_live_kpis: [],
    untagged_chats: {},
    live_chats: {},
    selected_active_chat_type: null,
    selected_active_chat_id: null,
    active_chats: {},
    unread_live_message_count: getDataFromLocalStorage(
      LOCAL_STORAGE.LIVE_CHATS_UNREAD_MESSAGE_COUNT,
      {}
    ),
    unseen_live_chats_stall_count: getDataFromLocalStorage(
      LOCAL_STORAGE.UNSEEN_LIVE_CHATS_STALL_COUNT,
      {}
    ),
    chat_page_joining: false,
    customer_profile_loading: false,
    buttonLoader: {
      loader: false,
      index: ""
    },
    replyInfo: {},
    notes_open: false,
    selected_chat_tab: "live-chats",

    tickets_chats: {},
    filtered_ticket_chats: {},
    selected_group: "All Groups",
    selected_priority: null,
    selected_active_ticket_chat_id: null,
    active_ticket_chats: {},

    ticket_agents: [],

    // accepting_chat: null,
    // rejecting_chat: null,
    // tags_loading: false
  },
}

export default states
