import dayjs from "dayjs"

const states = {
  feedback_details: {
    feedbacks: [],
    filters: {
      startDate: dayjs().subtract(1, "day"),
      endDate: dayjs(),
      platform: "all"
    },
    selected_feedback: null,
    messages: [],
    loading: false,
    load_error: false,
    fb_current_page: 1,
    fb_page_size: 10,
    fb_total: 0,
  },
}

export default states
