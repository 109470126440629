import { isEdge } from "react-flow-renderer"
import { MODULE_CONFIG } from "../../config/setup"
import states from "./states"
import {
  WF_UPDATE_STATE,
  WF_MORE_WORKFLOW_LOADED,
  WF_CREATED,
  WF_UPDATED,
  WF_DELETED,
  WF_DIAGRAM_RECEIVED,
  WF_UPDATE_DIAGRAM_STATE,
  WF_UPDTAE_CURRENT_ELEMENTS,
  WF_DELETE_CURRENT_ELEMENTS,
} from "./actiontypes"

const workflow_details = (state = states.workflow_details, action) => {
  switch (action.type) {
    case WF_UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      }

    //----------------------- Workflow Home ------------------------

    case WF_MORE_WORKFLOW_LOADED:
      return {
        ...state,
        workflows: [...state.workflows, ...action.payload],
        wf_has_more:
          action.payload.length ===
          MODULE_CONFIG.BOT_BUILDER.WF_RECORDS_PER_PAGE_DATA,
      }

    case WF_CREATED:
      return {
        ...state,
        workflows: [action.payload, ...state.workflows],
      }

    case WF_DELETED: {
      let index = -1
      index = state.workflows.findIndex(
        workflow => workflow.wid === action.payload
      )
      if (index !== -1)
        return {
          ...state,
          workflows: [
            ...state.workflows.slice(0, index),
            ...state.workflows.slice(index + 1),
          ],
        }
      return state
    }

    case WF_UPDATED: {
      let index = -1
      index = state.workflows.findIndex(
        workflow => action.payload.wid === workflow.wid
      )
      if (index !== -1)
        return {
          ...state,
          workflows: [
            ...state.workflows.slice(0, index),
            {
              ...state.workflows[index],
              ...action.payload,
            },
            ...state.workflows.slice(index + 1),
          ],
        }
      return state
    }

    //----------------------- Workflow Diagram ------------------------

    case WF_UPDATE_DIAGRAM_STATE: {
      if (action.payload.wid && state.wf_diagrams[action.payload.wid])
        return {
          ...state,
          wf_diagrams: {
            ...state.wf_diagrams,
            [action.payload.wid]: {
              ...state.wf_diagrams[action.payload.wid],
              ...action.payload.changedValue,
            },
          },
        }
      return state
    }

    case WF_DIAGRAM_RECEIVED:
      return {
        ...state,
        wf_diagrams: {
          ...state.wf_diagrams,
          [action.payload.wid]: {
            ...action.payload,
            synced: true,
          },
        },
        selected_diagram_id: action.payload.wid,
      }

    case WF_UPDTAE_CURRENT_ELEMENTS: {
      if (action.payload.wid && state.wf_diagrams[action.payload.wid]) {
        if (
          action.payload.nodeId &&
          (action.payload.updatedElement || action.payload.updatedElementData)
        ) {
          let elements = [...state.wf_diagrams[action.payload.wid].elements]
          if (action.payload.removeEdges)
            elements = elements.filter(ele => {
              let bool = true
              if (isEdge(ele)) {
                action.payload.removeEdges.forEach(rmEdge => {
                  if (
                    rmEdge.source === ele.source &&
                    rmEdge.sourceHandle === ele.sourceHandle
                  )
                    bool = false
                })
              }
              return bool
            })
          let index = -1
          index = elements.findIndex(i => i.id === action.payload.nodeId)
          if (index !== -1)
            return {
              ...state,
              wf_diagrams: {
                ...state.wf_diagrams,
                [action.payload.wid]: {
                  ...state.wf_diagrams[action.payload.wid],
                  synced: false,
                  elements: [
                    ...elements.slice(0, index),
                    {
                      ...elements[index],
                      ...(action.payload.updatedElement
                        ? action.payload.updatedElement
                        : {
                            data: {
                              ...elements[index].data,
                              ...action.payload.updatedElementData,
                            },
                          }),
                    },
                    ...elements.slice(index + 1),
                  ],
                },
              },
            }
          return state
        } else if (
          action.payload.newElements &&
          action.payload.newElements.length > 0
        ) {
          let elements = [...state.wf_diagrams[action.payload.wid].elements]
          elements = elements.filter(ele => {
            let bool = true
            if (isEdge(ele)) {
              action.payload.newElements.forEach(newElement => {
                if (
                  isEdge(newElement) &&
                  newElement.source === ele.source &&
                  newElement.sourceHandle === ele.sourceHandle
                )
                  bool = false
              })
            }
            return bool
          })
          return {
            ...state,
            wf_diagrams: {
              ...state.wf_diagrams,
              [action.payload.wid]: {
                ...state.wf_diagrams[action.payload.wid],
                synced: false,
                elements: [...elements, ...action.payload.newElements],
              },
            },
          }
        } else return state
      }
      return state
    }

    case WF_DELETE_CURRENT_ELEMENTS: {
      if (action.payload.wid && state.wf_diagrams[action.payload.wid]) {
        if (action.payload.nodeId)
          return {
            ...state,
            wf_diagrams: {
              ...state.wf_diagrams,
              [action.payload.wid]: {
                ...state.wf_diagrams[action.payload.wid],
                synced: false,
                elements: state.wf_diagrams[action.payload.wid].elements.filter(
                  ele =>
                    ele.id !== action.payload.nodeId &&
                    !(
                      ele.source === action.payload.nodeId ||
                      ele.target === action.payload.nodeId
                    )
                ),
              },
            },
          }
        else if (action.payload.edgeId)
          return {
            ...state,
            wf_diagrams: {
              ...state.wf_diagrams,
              [action.payload.wid]: {
                ...state.wf_diagrams[action.payload.wid],
                synced: false,
                elements: state.wf_diagrams[action.payload.wid].elements.filter(
                  ele => ele.id !== action.payload.edgeId
                ),
              },
            },
          }
      }
      return state
    }

    default:
      return state
  }
}

export default workflow_details
