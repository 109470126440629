import dayjs from "dayjs"

const states = {
  kpi_details: {
    stats_list: [],
    filters: [],
    selected_filters: {
      startDate: dayjs().subtract(1, "day"),
      endDate: dayjs().subtract(1, "day"),
    },
  },
}

export default states
