import dayjs from "dayjs"

//========================= DUMMY DATA ========================

export const fracture_list = [
  {
    id: "index0",
    type: "histogram",
    hasMore: true,
    title: "Histogram",
    filters: [
      {
        type: "customSelect",
        options: ["top 5", "top 10"],
        customOptions: ["china", "india", "america", "rusia", "england"],
        custom: true,
        name: "country",
      },
    ],
    selectedFilters: {
      country: ["china", "india"],
    },
    data: [
      { Count: 3, EventTags: "activation start" },
      { Count: 9, EventTags: "greeting start" },
      { Count: 17, EventTags: "collection success" },
      { Count: 16, EventTags: "OTP auth start" },
      { Count: 18, EventTags: "collection end" },
      { Count: 4, EventTags: "agent success" },
      { Count: 1, EventTags: "lpc start" },
    ],
    scale: {
      EventTags: { alias: "Event Tags" },
      Count: { alias: "Count" },
    },
    axis: {
      x: "EventTags",
      y: "Count",
      color: "EventTags",
    },
  },
  {
    id: "index1",
    type: "line",
    hasMore: false,
    title: "Line",
    data: [
      { month: "jan", city: "patna", count: 22 },
      { month: "jan", city: "delhi", count: 19 },
      { month: "jan", city: "noida", count: 29 },
      { month: "feb", city: "patna", count: 23 },
      { month: "feb", city: "delhi", count: 34 },
      { month: "feb", city: "noida", count: 44 },
      { month: "mar", city: "patna", count: 45 },
      { month: "mar", city: "delhi", count: 44 },
      { month: "mar", city: "noida", count: 34 },
      { month: "apr", city: "delhi", count: 56 },
      { month: "apr", city: "patna", count: 67 },
      { month: "apr", city: "noida", count: 54 },
      { month: "may", city: "delhi", count: 58 },
      { month: "may", city: "patna", count: 41 },
      { month: "may", city: "noida", count: 69 },
      { month: "jun", city: "patna", count: 34 },
      { month: "jun", city: "delhi", count: 32 },
      { month: "jun", city: "noida", count: 30 },
    ],
    scale: {
      month: {
        range: [0, 1],
      },
    },
    axis: {
      x: "month",
      y: "count",
      color: "city",
    },
  },
]

//===============================================================

const states = {
  fracture_details: {
    fracture_list: [],
    filters: [],
    selected_filters: {},
    selected_date: {
      startDate: dayjs().subtract(1, "day"),
      endDate: dayjs(),
    },
  },
}

export default states
