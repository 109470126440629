import dayjs from "dayjs"

const states = {
  event_details: {
    events: [],
    events_loading: false,
    events_load_error: false,
    histogram: [],
    histogram_filters: {
      startDate: dayjs().subtract(1, "day"),
      endDate: dayjs(),
    },
  },
}

export default states
