import dayjs from "dayjs"

const states = {
  chat_analytics_details: {
    //=================== filters =================
    selected_date_range: [
      dayjs().subtract(3, "day"),
      dayjs(),
    ],
    selected_platform: "all",
    //================= Stats ===================
    stats: [],
    //================= Agent Chart ===================
    agent_chart_data: [],
    ac_granularity: "day",
    ac_scale: {
      total: {
        min: 0,
      },
      queued: {
        min: 0,
      },
      timestamp: {
        type: "time",
        tickInterval: 60 * 60 * 1000 * 24 * 2, // 2 day
        mask: "DD MMM",
      },
    },
    ac_loading: false,
    ac_load_error: false,
    //================= Queued Chats Chart ===================
    queued_chats_data: [],
    qc_granularity: "day",
    qc_scale: {
      count: {
        min: 0,
      },
      timestamp: {
        type: "timeCat",
        tickInterval: 1,
        mask: "DD MMM",
      },
    },
    qc_loading: false,
    qc_load_error: false,
    //================= First Response Time ===================
    frt_chart_data: [],
    frt_granularity: "day",
    frt_scale: {
      Seconds: {
        min: 0,
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    frt_loading: false,
    frt_load_error: false,
    //================= Agent Handling Time ===================
    aht_chart_data: [],
    aht_granularity: "day",
    aht_scale: {
      Minutes: {
        min: 0,
      },
      timestamp: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    aht_loading: false,
    aht_load_error: false,
    //================= Answered Chart ===================
    answered_chart_data: [],
    ans_granularity: "day",
    ans_scale: {
      count: {
        min: 0,
      },
      timestamps: {
        type: "timeCat",
        mask: "DD MMM",
        tickInterval: 1,
      },
    },
    ans_loading: false,
    ans_load_error: false,
  },
}

export default states
